import React from "react";
import "../../Style.css";
import ElementBorders from "../ElementBorders";

function BottomButtom() {
  return (
    <div className="Bottom-container-content">
      <ElementBorders
        enlace="https://wa.me/528711385025"
        classcolor="black-btn"
        texto="CONTACTO"
      />
    </div>
  );
}

export default BottomButtom;
